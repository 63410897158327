<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return"><el-button type="text" size="small" @click="$parent.checkSaveStatus">返回</el-button>
				</p>
				<div class="h-b"><el-button type="text" size="small" @click="saveData" v-right-code="'Organization:Getempty'">保存</el-button></div>
			</div>
		</div>
		<div class="form-list">
			<el-form ref="_dataInfoForm" :model="dataSource" :rules="dataCheckRule">
				<el-form-item>
					<el-col :span="3" class="form-title">
						<span style="color:red;">*</span>
						组织机构编码：
					</el-col>
					<el-col :span="7">
						<el-form-item prop="Code">
							<el-input v-model.trim="dataSource.Code" :maxlength="30" :minlength="4" :disabled="true" placeholder=""></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title">
						<span style="color:red;">*</span>
						组织机构名称：
					</el-col>
					<el-col :span="7">
						<el-form-item prop="Name">
							<el-input v-model.trim="dataSource.Name" :maxlength="50" placeholder=""></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>

				<el-form-item>
					<el-col :span="3" class="form-title">
						组织机构简称：
					</el-col>
					<el-col :span="7">
						<el-form-item prop="ShortName">
							<el-input v-model.trim="dataSource.ShortName" :maxlength="50" placeholder=""></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title">
						上级组织机构编码：
					</el-col>
					<el-col :span="7">
                        <el-form-item prop="ParentCode">
                            <el-input v-model="dataSource.ParentCode" readonly suffix-icon="el-icon-search" placeholder="请选择上级组织" @click.native="organizationOptions.visible = true"></el-input>
                        </el-form-item>
					</el-col>
				</el-form-item>

				<el-form-item>
					<el-col :span="3" class="form-title">
						上级组织机构名称：
					</el-col>
					<el-col :span="7">
						<el-form-item prop="ParentName">
							<el-input v-model.trim="dataSource.ParentName" :maxlength="50" placeholder="" :disabled="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title">
						<span style="color:red;">*</span>
						状态：
					</el-col>
					<el-col :span="7">
						<el-form-item prop="Status">
							<el-select v-model="dataSource.Status" :maxlength="200" :disabled="true">
								<el-option v-for="item in StatusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-form-item>

				<el-form-item>
					<el-col :span="3" class="form-title">
						<span style="color:red;">*</span>
						组织机构类型：
					</el-col>
					<el-col :span="7">
						<el-form-item prop="Type">
							<el-select v-model="dataSource.Type" :maxlength="200">
								<el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="3" class="form-title">
						<span style="color:red;">*</span>
						组织机构类别：
					</el-col>
					<el-col :span="7">
						<el-form-item prop="Classification">
							<el-select v-model="dataSource.Classification" :maxlength="200">
								<el-option v-for="item in classList" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
				</el-form-item>

				<el-form-item>
					<el-col :span="3" class="form-title">
						<span style="color:red;"></span>
						组织描述：
					</el-col>
					<el-col :span="17">
						<el-form-item prop="OrgDescribe">
							<el-input type="textarea" :rows="3" placeholder="" v-model="dataSource.OrgDescribe"></el-input>
						</el-form-item>
					</el-col>
				</el-form-item>
			</el-form>
		</div>
		<el-tabs type="border-card">
			<el-tab-pane label="关联用户">
				<div class="form-padding">
					<el-table :data="dataSource.UserList" header-align="center" @select="selectRow">
						<el-table-column prop="UserName" align="center" label="登录账号"></el-table-column>
						<el-table-column prop="Mobile" align="center" label="手机"></el-table-column>
						<el-table-column prop="NickName" align="center" label="昵称"></el-table-column>
						<el-table-column prop="DisplayUserStatus" align="center" label="状态"></el-table-column>
						<el-table-column prop="DisplayIsSuperAdmin" align="center" label="管理员"></el-table-column>
						<el-table-column prop="DisplaySysBizType" align="center" label="用户类型"></el-table-column>
					</el-table>
				</div>
			</el-tab-pane>
		</el-tabs>
        <DialogEx :options="organizationOptions" title="选择机构" @onOk="onOrganizationOk">
            <Organization ref="organization" :config="organizationConfig" @onSelectedRow="onOrganizationSelectedRow" @onDBClick="onOrganizationOk"></Organization>
        </DialogEx>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isDisabled: false,
				dataCheckRule: {
					Name: [{ required: true, message: '组织机构名称不能为空', trigger: 'blur' }],
					Type: [{ required: true, message: '组织机构类型不能为空', trigger: 'change' }],
					Classification: [{ required: true, message: '组织机构类别不能为空', trigger: 'change' }],
					Status: [{ required: true, message: '状态不能为空', trigger: 'change' }],
				},
				StatusList: [{
					label: '启用',
					value: 100
				}, {
					label: '停用',
					value: 200
				}],
				classList: [{
					label: '公司',
					value: 0
				}, {
					label: '部门',
					value: 1
				}],
				typeList: [{
					label: '内部组织',
					value: 0
				}, {
					label: '外部组织',
					value: 1
				}],
				selectUsers: [],
                organizationList:[],
                organizationConfig: {
                    isDetailDisplay: true,
                },
                organizationOptions: {
                    visible: false,
                    size: 'small'
                },
                currentOrganizationSelectedRow: {}
			};
		},
		props: {
			config: {
				isDetailDisplay: false
			},
			dataSource: {}
		},
		mounted() {
			this.Event.$on('clearEditUserForm', () => this.resetForm);
		},
		watch: {
			dataSource: {
				handler(curVal, oldVal) {
					this.isDisabled = this.dataSource.Id != null;
				},
				deep: true
			}
		},
		methods: {
            //选择机构
            onOrganizationSelectedRow: function (row) {
                this.currentOrganizationSelectedRow = row;
            },
            onOrganizationOk: function (isDbClick) {
                var row = this.currentOrganizationSelectedRow;
                if (row instanceof Object && row.Id != null) {
                    this.dataSource.ParentCode = row.Code;
                    this.dataSource.ParentName = row.Name;
                }
                if (isDbClick) {
                    this.organizationOptions.visible = false;
                }
            },
			resetForm() {
				!this.isPassValidate && this.$refs['_dataInfoForm'].resetFields(); //清空表单
			},
			selectRow(row) {
				this.selectUsers = row;
			},
			//保存
			saveData() {
				var _this = this;
				_this.$refs["_dataInfoForm"].validate((valid) => {
					_this.isPassValidate = valid;
					if (valid) {
						_this.$ajax.send("omsapi/organization/createOrUpdate", "post", _this.dataSource, (data) => {
							_this.dataSource.Id === null && (_this.dataSource.Id = data.Result);
							_this.$parent.syncDataSource(data.Result);
							_this.Event.$emit(_this.config.moduleEvent.initial);
							_this.Utils.messageBox('保存成功.', 'success');
						});
					} else {
						return false;
					}
				});
			}
		},
		components: {
            "Organization": resolve => { require(['@/components/enterprise/selector/organizationselector.vue'], resolve) }
		}
	};
</script>

<style></style>